  <template>
    <div class="pricelist"> 
   
      
    <template>
      <v-container class="bg-surface-variant">
        <v-row no-gutters>
          <v-col cols="12">
            <v-sheet class="pa-2 ma-2">
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <!-- Menu Sincronizar-->
                  <template>
                          <!-- FIN Dialog confirmación -->
                          <v-card flat  class="py-1" >
                                <v-card-text >
                                  <v-row class="text-right" justify="center" >        
                                    <v-btn-toggle
                                      v-model="toggle_exclusive"
                                      mandatory
                                    >
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">              
                                            <v-btn
                                              @click="downloadExcel"
                                              color="green"
                                              v-bind="attrs"
                                              v-on="on"
                                            >                
                                              <v-icon>file_present</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Descargar Lista de Precios con Stock</span>
                                        </v-tooltip>
                                    </template> 

                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">                      
                                            <v-btn
                                              class="orange"
                                              @click="syncStock"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon >refresh</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Actualizar Stock</span>
                                        </v-tooltip>
                                    </template>                                                                
                                  
                                    <!-- Dialog para agregar --> 
                                    <!-- Comparar Precios -->                            
                                    <!-- <template>  
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">              
                                          <router-link to="/pw-product">
                                              <v-btn
                                                primary
                                                color="darken-2"               
                                                v-bind="attrs"
                                                v-on="on"
                                              >                                
                                              <v-icon>list</v-icon>
                                              </v-btn>
                                            </router-link> 
                                          </template>
                                          <span>Comparacion de Productos</span>
                                        </v-tooltip>
                                    </template> -->
                                    <!-- FIN Comparar Precios -->                            

                                    <!-- Crear producto CT -->
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">                      
                                            <v-btn
                                              class="red"
                                              @click="getProduct('CTOnline')"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon >download</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Crear Productos de CT</span>
                                        </v-tooltip>
                                    </template> 
                                    <!-- FIN Crear producto CT -->
                                    <!-- Subir producto de CT a WC -->
                                    <!-- <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">                      
                                            <v-btn
                                              class="red default"
                                              @click="upWCAll('CT')"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon >upload</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Subir los productos de CT a WC</span>
                                        </v-tooltip>
                                    </template>  -->
                                    <!-- FIN Subir producto de CT a WC -->
                                    
                                    <!-- Crear producto de CVA a WC -->
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">                      
                                            <v-btn
                                              class="green default"
                                              @click="getProduct('CVA')"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon >download</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Crear productos de CVA</span>
                                        </v-tooltip>
                                    </template> 
                                    <!-- FIN Crear producto de CVA a WC -->
                                    
                                    <!-- Subir producto de CVA a WC -->
                                    <!-- <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">                      
                                            <v-btn
                                              class="green default"
                                              @click="upWCAll('CVA')"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon >upload</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Subir los productos de CVA a WC</span>
                                        </v-tooltip>
                                    </template>  -->
                                    <!-- FIN Subir producto de CVA a WC -->

                                    <!-- Crear producto de Syscom -->
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">                      
                                            <v-btn
                                              class="blue default"
                                              @click="getProductSyscom"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon >download</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Crear productos de Syscom</span>
                                        </v-tooltip>
                                    </template> 
                                    <!-- FIN Crear producto de Syscom-->  
                                                  
                                    <!-- Subir producto de Syscom a WC -->
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">                      
                                            <v-btn
                                              class="purple default"
                                              @click="upWCAll()"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon >upload</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Subir los productos de CT, CVA y Syscom a WC</span>
                                        </v-tooltip>
                                    </template> 
                                    <!-- FIN Subir producto de Syscom a WC -->                                          
                                    </v-btn-toggle>
                                  </v-row>
                                </v-card-text>
                          </v-card>                            
                  </template>
                  <!-- FIN Menu Sincronizar -->
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <!-- Menu Acciones-->
                  <template>
                          <!-- FIN Dialog confirmación -->
                          <v-card flat  class="py-1" >
                                <v-card-text >
                                  <v-row class="text-right" justify="center" >        
                                    <v-btn-toggle
                                      v-model="toggle_exclusive"
                                      mandatory
                                    >
                                      
                                    <!-- Dialog para agregar Producto -->                     
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }"> 
                                            <v-btn
                                              color="green"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-dialog
                                                v-model="dialog"
                                                persistent
                                                max-width="600px"                 
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-btn
                                                    color="green"            
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    plain
                                                  >
                                                    <v-icon>add</v-icon>
                                                  </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>
                                                      <span class="headline">Agregar Producto / Servicio</span>
                                                      <v-btn
                                                        class="headline"
                                                        color="blue darken-1"
                                                        text
                                                        @click="dialog = false"
                                                      >
                                                        <v-icon>close</v-icon>
                                                      </v-btn>
                                                    </v-card-title>
                                                    <v-card-text>
                                                      <v-container>  
                                                        <!--<ProductForm :submitForm="AddPricelist" buttonText="Guardar" hasProduct=true hasDescription=true />-->
                                                        <ProductCreate />
                                                      </v-container>
                                                      
                                                    </v-card-text>
                                                    <v-card-actions>
                                                      <v-spacer></v-spacer>          
                                                      <small style="color:red;">*Campos Obligatorios</small>
                                                      <!-- <v-btn 
                                                        color="blue darken-1"
                                                        text
                                                        v-on:click="submit"><v-icon>save</v-icon>
                                                      </v-btn>         -->
                                                    </v-card-actions>
                                                  </v-card>
                                                  </v-dialog>
                                            </v-btn>                
                                          </template>
                                          <span>Agregar Producto</span>
                                        </v-tooltip>
                                    </template>   
                                    <!-- FIN Dialog para agregar Producto -->                     
                                    <!-- Comparar Precios -->                            
                                    <!-- <template>  
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">              
                                          <router-link to="/pw-product">
                                              <v-btn
                                                primary
                                                color="darken-2"               
                                                v-bind="attrs"
                                                v-on="on"
                                              >                                
                                              <v-icon>list</v-icon>
                                              </v-btn>
                                            </router-link> 
                                          </template>
                                          <span>Comparacion de Productos</span>
                                        </v-tooltip>
                                    </template> -->
                                    <!-- FIN Comparar Precios -->                                                                                                                              
                                    
                                    <!-- Otra Vista -->
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">    
                                            <router-link to="/precios">                                          
                                              <v-btn
                                                primary
                                                color="darken-2"               
                                                v-bind="attrs"
                                                v-on="on"
                                              >                                
                                              <v-icon>grid_view</v-icon>
                                              </v-btn>
                                            </router-link> 
                                          </template>
                                          <span>Vista de Lista</span>
                                        </v-tooltip>
                                    </template>
                                    <!-- FIN Otra Vista -->

                                    <!-- Regresar -->
                                    <template>  
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">              
                                            <v-btn
                                              @click="$router.back()" 
                                              color="primary darken-2"               
                                              dark
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                            <v-icon>arrow_back</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Regresar a la Página Anterior</span>
                                        </v-tooltip>
                                    </template>
                                    <!-- FIN Regresar -->
                                      
                                      
                                    </v-btn-toggle>
                                  </v-row>
                                </v-card-text>
                          </v-card>                            
                  </template>
                  <!-- FIN Menu Acciones -->
                </v-col>              
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <!-- Busqueda -->
                  <template>
                    <v-container class="grey lighten-5">
                      <v-row>
                        <v-col>
                            <FieldSearch @priceslist-id="getProducts($event)"  />
                            <!-- <FieldSearch2 hasPricelist=true  /> -->
                          </v-col>
                        </v-row>                                                                            
                    </v-container>
                  </template>
                  <!-- FIN Busqueda -->
                </v-col>
                <v-col cols="12" sm="12" md="10" lg="10">
                  <!-- content -->
                    <PricelistItemList :pricelist="priceslist"  />
                  <!-- FIN content -->
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <!-- filter -->
                  <template>
  <v-card
    class="mx-auto"
    max-width="400"
  >
    <v-toolbar color="purple">
      <v-toolbar-title>Filtros de Busqueda</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>


    <v-divider></v-divider>

    <v-list lines="three" select-strategy="classic">
      <v-list-subheader>Stock</v-list-subheader>
      <v-list-item value="notifications">
        <v-radio-group
          v-model="stock"
          column
          >
          <!-- v-for="pstock in stocklist" :key="pstock.id" -->
        <!-- :label="`${pstock.description}`" -->
        <!-- :value="`${pstock.value}`" -->
          <v-radio
            label="Todos"
            value="all"
          ></v-radio>
          <v-radio
            label="En Camino"
            value="camino"
          ></v-radio>
          <v-radio
            label="En Stock"
            value="stock"
          ></v-radio> 
          <v-radio
            label="Minimo"
            value="minstock"
          ></v-radio> 
          <v-radio
            label="Maximo"
            value="maxstock"
          ></v-radio>     
          <v-radio
            label="Syscom"
            value="ssyscom"
          ></v-radio>  
          <v-radio
            label="CT"
            value="sctonline"
          ></v-radio> 
          <v-radio
            label="CVA"
            value="scva"
          ></v-radio>  
        </v-radio-group>
        <!-- <v-checkbox  class="my-0 py-0" v-model="stock" :value="`${pstock.value}`" :label="`${pstock.description}`"> </v-checkbox> -->
      </v-list-item>
    </v-list>
  </v-card>
</template>
                   <!-- FIN filter -->
                </v-col>

              </v-row>
                          
            </v-sheet>
          </v-col>

        </v-row>
      </v-container>
    </template>

      
  
  
  
       
  
    
  
    </div>
  </template>
  
  <script>
  // import axios from "axios";
  import{ mapMutations,mapState,mapGetters } from "vuex";
  import exportFromJSON from "export-from-json";
  import FieldSearch from '@/components/FieldSearchPrice.vue'
  // import FieldSearch2 from '@/components/FieldSearch2'
  
  // import PricelistItem from '@/components/PricelistItem'
  import PricelistItemList from "@/components/PricelistItemList";
  import ProductCreate from "@/components/ProductCreate";
  import baseApi from '@/services/baseApi';
  import router from '@/router';
  
  
  export default {
    name: 'pricelist',
    computed:{
        // ...mapState(['currentUser']),
        ...mapState(['brands','measurements','familycategories','currentUser']),
        ...mapGetters({
          // priceslist:'filteredPrices',
          // priceslist2:'filteredStock'
        })   
    },
    components: {
        FieldSearch,
        // FieldSearch2,
        PricelistItemList,
        ProductCreate
    },
    methods:{
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
      async upWCAll(api){
        let color='default';
        if (api=='CVA')color='green'
        else if(api=='CT') color='red'
        else if(api=='Syscom') color='blue'

        try{                   
          this.showLoading({titulo:"Subiendo la información a Woocommerce",color:color})   
          // await baseApi().get(`/woo/products?sku=2024002058`).then((response)=>{                    
          await baseApi().get(`/woo/products/createall?api=`+api).then((response)=>{        
          // await baseApi().get(`/woo/products/categories`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      },
      async getProductSyscom(){
        // path='/syscom/createproducts'
        // color='blue';
        // this.showLoading({titulo:"Accediendo a la información",color:'blue'})


        this.$swal({
              title: "De una categoria de Syscom",
              // input: "text",
              // inputPlaceholder: "3006 / 66416 / 469",
              // inputAttributes: {
              //   autocapitalize: "off"
              // },
              input: "select",
              inputOptions: {
                             
                ControlAcceso: {
                  65945: "Lectores Tarjetas",
                  2554: "Todos",
                },
                469: "Videoporteros e Interfonos",
                3006: "Autónomas / Inteligentes",
                66448: "Soluciones de energia portatiles",
                66315: "Climatizacion",
                66416: "Modulo Solar",

              },
              inputPlaceholder: "Selecciona una categoria",
              showCancelButton: true,
              confirmButtonText: "Enviar",
              showLoaderOnConfirm: true,
              preConfirm: async (category_syscom) => {
                // alert(category_syscom)
                try{                               
                  // path='/syscom/createproducts'
                  // color='blue';
                  this.showLoading({titulo:"Accediendo a la información",color:'blue'})
              
                  // this.showLoading({titulo:"Accediendo a la información",color:color})
                  await baseApi().post(`/syscom/createproducts`,JSON.stringify({ category_syscom })).then((response)=>{
                  // await baseApi().get('/syscom/createproducts').then((response)=>{                       
                      if(!response.data.error){                            
                        this.$swal("Genial!",response.data.message, "success");
                      }else{
                        this.$swal("Error!",response.data.message, "error");
                      }                                    
                  });              
                }catch(error){
                  //console.log(error);
                }finally{
                  this.hiddenLoading()
                  //this.$swal("Genial!", this.rdesc, this.rtitle);
                }
                
                // try {
                //   const githubUrl = `
                //     https://api.github.com/users/${login}
                //   `;
                //   const response = await fetch(githubUrl);
                //   if (!response.ok) {
                //     return this.$swal.showValidationMessage(`
                //       ${JSON.stringify(await response.json())}
                //     `);
                //   }
                //   return response.json();
                // } catch (error) {
                //   this.$swal.showValidationMessage(`
                //     Request failed: ${error}
                //   `);
                // }
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                this.$swal.fire({
                  title: `${result.value.login}'s avatar`,
                  imageUrl: result.value.avatar_url
                });
              }
            });
            
          
      },
      async getProduct(api){
        let color=''
        let path=''

        if (api=='Syscom') {
          path='/syscom/createproducts'
          color='blue'
        }else if (api=='CTOnline') {
          path='/ctconnect/updatepriceall'
          color='red'
          //code
        }else if (api=='CVA') {
        // }else if (api=='CVA') {
          path='/cva/createproducts'
          color='green'
        }else {
          //code
        }

        try{                   
          this.showLoading({titulo:"Accediendo a la información",color:color})

          await baseApi().get(path).then((response)=>{                       
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }

      },            
      getProducts(rows){
        // this.showLoading({titulo:"Accediendo información",color:"primary"}) 
        // console.log(this.$route.name+' '+sword);
        // console.log('Vista');
        // console.log(rows);
        this.priceslist=rows;

      },
      async getBrand(){
       
        try{                   
          this.showLoading({titulo:"Accediendo a la información",color:'default'})
          await this.$store.dispatch('loadBrands',this.attribute);
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }

      },  
      async downloadExcel() {              
        try{
          await baseApi().get(`/precios/querycategoryall`).then((response)=>{          
            if(!response.data.error){    
              for (var i = 0; i < response.data.length; i+=1) {              
              // console.log("Pricelist: "+response.data[0].description)
                this.investorsList[i]={
                  sku: response.data[i].sku,
                  descripcion: response.data[i].description,
                  tipo: response.data[i].type_article,                  
                  familia: response.data[i].familyname,
                  subfamilia: response.data[i].subfamilyname,
                  precio: response.data[i].price,
                  moneda: response.data[i].currencyabraviada,
                  preciopublico: parseFloat(response.data[i].price_publico),
                  stock: response.data[i].stock,
                  minstock: response.data[i].min_stock,
                  maxstock: response.data[i].max_stock,
                  camino: response.data[i].camino,
                };                 
              }
              this.$swal("Genial!",'Se exporto la informacion correctamente', "success");
            }else{              
                  this.$swal("Error!",response.data.message, "error");
            }                                    
              // console.log("VENTA: "+response.data.message);
          }); 
  
          const data = this.investorsList;
          const fileName = "articulos_icheck";
          const exportType = exportFromJSON.types.csv;
    
          if (data) exportFromJSON({ data, fileName, exportType });

        }catch(error){
          if (error.message=='Request failed with status code 500') {
            this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
          }else if(error.message=='Request failed with status code 401'){
            this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
            router.push('/login');
          }else{
            this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
          } 
        }finally{
          this.hiddenLoading()
        } 
      },        
      async syncStock(){
        try{        
           //console.log(this.sword2);       
          // let branchID=this.currentUser.branch_id;
          this.showLoading({titulo:"Actualizando Stock",color:"warning"})            
          await baseApi().get(`/stock/sync`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
          });              
        }catch(error){
           //console.log(error);
        }finally{
           this.hiddenLoading()
           //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      }
    },
    data(){
       return{   
        investorsList: [],
        stocklist:[
          {
            id:0,
            description:'iCheck',
            value:'icheck'
          },
          {
            id:1,
            description:'En Camino',
            value:'camino'
          },
          {
            id:2,
            description:'Minimo',
            value:'minimo'
          },
          {
            id:3,
            description:'Maximo',
            value:'maximo'
          },
          {
            id:4,
            description:'Syscom',
            value:'syscom'
          },
          {
            id:5,
            description:'CT',
            value:'ct'
          },
          {
            id:6,
            description:'CVA',
            value:'cva'
          },
          {
            id:7,
            description:'Todos',
            value:'all'
          }
        ],
        // investorsList: [
        //   {
        //     id: 0,
        //     descripcion: "Gautam",
        //     email: "gautam@example.com",
        //     investment: "Stocks",
        //   },
        //   {
        //     id: 1,
        //     descripcion: "Sam",
        //     email: "sam@example.com",
        //     investment: "Bonds",
        //   },
        //   {
        //     id: 2,
        //     descripcion: "Tim",
        //     email: "tim@example.com",
        //     investment: "Options",
        //   },
        //   {
        //     id: 3,
        //     descripcion: "Kim",
        //     email: "kim@example.com",
        //     investment: "Stocks",
        //   },
        //   {
        //     id: 4,
        //     descripcion: "John",
        //     email: "john@example.com",
        //     investment: "Options",
        //   },
        //   {
        //     id: 5,
        //     descripcion: "Lee",
        //     email: "lee@example.com",
        //     investment: "Stocks",
        //   },
        //   {
        //     id: 6,
        //     descripcion: "Charlotte",
        //     email: "charlotte@example.com",
        //     investment: "Options",
        //   },
        //   {
        //     id: 7,
        //     descripcion: "Amy",
        //     email: "amy@example.com",
        //     investment: "Stocks",
        //   },
        //   {
        //     id: 8,
        //     descripcion: "Mark",
        //     email: "mark@example.com",
        //     investment: "Bonds",
        //   },
        //   {
        //     id: 9,
        //     descripcion: "Rose",
        //     email: "rose@example.com",
        //     investment: "Stocks",
        //   },
        // ],
        attribute: {sword: 'All',limit: 500},
        dialog: false,
        dialog1: false,
        toggle_exclusive:"",
        sword:"",      
        priceslist:[],
        sfecha:new Date().toISOString().substr(0,10),
        theader: [
          { text: 'Acción', value: 'canasta_basica' },
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'SKU', value: 'productsku' },
        ],
      }
    },
    created(){
        this.changeBreadcrumbs({
            page:"Precios",
            title:"Lista de Precios",
            subtitle:"primary",
            description:"En esta lista encontrará todos los precios dados de alta en el sistema desde el principio de los tiempos"
        });
        // this.getBrand();
    }, 
  }
  </script>
  <style>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .np-list {
    padding: 2px 8px;
    margin: 12px 8px;
    border: 1px solid #107fda;
    background: #ffffff;
    border-radius: 6px;
    color: #107fda;
  }
  .np-btn {
    padding: 2px 8px;
    margin: 12px 8px;
    border: 1px solid #107fda;
    width: 110px;
    background: #107fda;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
  }
  </style>
