<template>
<div id="ProductForm">  
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  > 
  <v-row>  
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-model="productInfo.ctonline_id"
                      v-if="hasProduct"
                      label="*Clave CTOnline"
                      :rules="[
                        //required('syscom_id'),
                        //onlyNumeric('syscom_id'),
                      ]"
                  ></v-text-field>   
                  <v-switch
                    v-if="hasProduct"
                    v-model="productInfo.ctonline"
                    label="CTOnline"
                  ></v-switch>    
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-model="productInfo.cva_id"
                      v-if="hasProduct"
                      label="*Clave CVA"
                      :rules="[
                        //required('syscom_id'),
                        //onlyNumeric('syscom_id'),
                      ]"
                  ></v-text-field>   
                  <v-switch
                    v-if="hasProduct"
                    v-model="productInfo.cva"
                    label="CVA"
                  ></v-switch>    
                </v-col>
                <v-col cols="12" sm="6" md="3">   
                  <v-text-field
                      v-model="productInfo.syscom_id"
                      v-if="hasProduct"
                      label="*Clave Syscom"
                      :rules="[
                        //required('syscom_id'),
                        //onlyNumeric('syscom_id'),
                      ]"
                  ></v-text-field>  
                  <v-switch
                    v-if="hasProduct"
                    v-model="productInfo.syscom"
                    label="Syscom"
                  ></v-switch>              
                </v-col>
                <v-col cols="12" sm="6" md="3">                      
                  <v-btn
                    v-if="hasProductCreate"
                    color="primary"
                    class="mr-4"
                    @click="getSyscom(productInfo.syscom_id )" 
                  >
                        <v-icon>search</v-icon> Obtener 
                        <!-- {{buttonText}} -->
                  </v-btn>       
                </v-col>        
            </v-row>

            <v-row>               
              <v-col cols="12" sm="6" md="4"> 
                <v-text-field
                    v-model="productInfo.clave_sat"
                    v-if="hasProduct"
                    label="Clave SAT"
                ></v-text-field>     
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-model="productInfo.quote_id"
                    v-if="hasEverybody"
                    label="Cotización ID*"
                    :rules="[
                      required('cotización')
                    ]"       
                  ></v-text-field>
                  <v-text-field
                    v-model="productInfo.salesorder_id"
                    v-if="hasEverybody"
                    label="Venta ID*"

                    :rules="[
                      required('venta')
                    ]"       
                  ></v-text-field>
                  <v-text-field
                    v-model="productInfo.purchase_id"
                    v-if="hasEverybody"
                    label="Compra ID*"
                    :rules="[
                      required('compra')
                    ]"       
                  ></v-text-field>
                  <v-text-field
                    v-model="productInfo.modelo"
                    v-if="hasProduct"
                    label="Modelo"                  
                  ></v-text-field>                  
              </v-col>    
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-model="productInfo.sku"
                    v-if="hasProduct"
                    label="SKU"                  
                ></v-text-field>
              </v-col>    

            </v-row>
            <v-row>
                <v-col cols="12">                                  
                  <v-text-field
                    v-model="productInfo.date_buy"
                    v-if="hasPurchaseAll"
                    label="Fecha de Compra*"
                    :rules="[
                      dateFormat('fecha de compra')
                    ]"          
                  /> 
                </v-col>
              </v-row>        
              <v-row>                
                <v-col cols="4"> 
                  <v-autocomplete
                    v-if="hasProduct"
                    v-model="productInfo.brand_id"
                    :hint="`${productInfo.brand_id}`"
                    :items="brands"
                    item-text="name"
                    item-value="id"
                    label="Selecciona una Marca"  
                    outlined
                  ></v-autocomplete> 
                                                   
                  <!-- <ejs-combobox                     
                    v-model="productInfo.brand_id"
                    v-if="hasProduct"
                    label="Marca*"
                    :dataSource='dataItemBrand' 
                    :fields='dataFieldBrand' 
                    placeholder='Seleccione una marca' 
                    popupWidth="250px"
                    :rules="[
                      required('marca')
                    ]"       
                  >
                  </ejs-combobox>                                     -->
                </v-col>               
                <v-col cols="4"> 
                  <v-autocomplete
                    v-if="hasProduct"
                    v-model="productInfo.famcategory_id"
                    :hint="`${productInfo.famcategory_id}`"
                    :items="familycategories"
                    item-text="name"
                    item-value="id"              
                    label="Selecciona una Familia"  
                    outlined
                  ></v-autocomplete>    
                </v-col>     
                <v-col cols="4"> 
                  <v-autocomplete
                    v-if="hasProduct"
                    v-model="productInfo.subfamily_id"
                    :hint="`${productInfo.subfamily_id}`"
                    :items="subfamilies"
                    item-text="name"
                    item-value="id"              
                    label="Selecciona una Sub-Familia"  
                    outlined
                  ></v-autocomplete>    
                </v-col>               
              </v-row>  
              <v-row>
                <v-col cols=12 sm="6" md="6" >
                  <v-autocomplete
                  v-model="productInfo.supplier_id"
                  v-if="hasPurchaseAll"
                  :hint="`${suppliers.id} - ${suppliers.razon_social}`"
                  :items="suppliers"
                  item-text="razon_social"
                  item-value="id"
                  label="Selecciona un Proveedor"  
                  outlined
                  ></v-autocomplete>
                  
                  <v-text-field
                  v-model="productInfo.filename"          
                  v-if="hasProduct"
                  label="URL Imagen"
                  :rules="[
                    // required('Url Imagen')
                  ]"          
                  /> 
              </v-col>
              <v-col cols=12 sm="6" md="6" >
                
                
                <v-autocomplete
                v-if="hasProduct"
                v-model="productInfo.measurement_id"
                :hint="`${productInfo.measurement_id}`"
                :items="measurements"
                item-text="name"
                item-value="id"              
                label="Selecciona una Unidad Medida"  
                outlined
                ></v-autocomplete>   
              </v-col>

              </v-row>            
              <v-row>
                <v-col cols="12">                                
                  <v-autocomplete
                    v-model="productInfo.pricelist_id"
                    v-if="hasSalesOrder || hasQuote || hasPurchase || hasPurchaseAll"
                    :items="priceslist"
                    item-text="description"
                    item-value="id"
                    label="Selecciona un producto*"
                    outlined
                  ></v-autocomplete>                  
                </v-col>
              </v-row>            
              <v-row>
                <v-col cols="12">                                
                  <v-text-field
                    v-model="productInfo.posicion"
                    v-if="hasSalesOrder || hasQuote || hasPurchase"
                    label="Posicion"
                    :rules="[
                      required('Posicion')
                    ]"          
                  />                         
                </v-col>
              </v-row>            
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="productInfo.description"          
                    v-if="hasDescription"
                    label="Descripción"
                    :counter="120" 
                    :rules="[
                      required('Descripción')
                    ]"          
                  />         
                </v-col>                
              </v-row>            
              <v-row>              
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="productInfo.price"
                    v-if="hasQuote || hasPurchase || hasProduct || hasPurchaseAll || hasSalesOrder"
                    label="Precio*"
                    value="10.00"
                    prefix="$"
                    :rules="[
                      required('precio'),
                      onlyNumeric('precio'),
                      NoComas('precio')
                    ]"       
                  />                  
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    v-if="hasProduct"
                    v-model="productInfo.type_article" 
                    :items="itemsTypeArticle"
                    label="Seleccione una tipo de artículo"
                    :rules="[
                      required('tipo de articulo')
                    ]"       
                  ></v-combobox>                        
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-if="hasQuote || hasPurchase || hasSalesOrder || hasPurchaseAll"
                    v-model="productInfo.cantidad"
                    label="Cantidad*"
                    value="1"
                    :rules="[
                      required('Cantidad')
                    ]"       
                  />      
                  <v-combobox
                    v-if="hasProduct"
                    v-model="productInfo.status" 
                    :items="itemsStatus"
                    label="Seleccione un Status"
                    :rules="[
                      required('status')
                    ]"       
                  ></v-combobox>                                                      
                </v-col>
              </v-row> 
              <v-row v-if="hasProduct">    
                <v-col cols="12" sm="3" md="3">    </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="productInfo.min_stock"
                    label="Min. Stock*"    
                    :rules="[
                      // required('maximo stock'),
                      onlyNumeric('maximo stock'),
                      // NoComas('maximo stock')
                    ]"                                            
                  />                  
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="productInfo.max_stock"
                    label="Max. Stock*"
                    :rules="[
                      // required('maximo stock'),
                      onlyNumeric('maximo stock'),
                      // NoComas('maximo stock')
                    ]"       
                  />      
                </v-col>
                <v-col cols="12" sm="3" md="3">    </v-col>
              </v-row> 
              <v-row  v-if="hasPurchaseAll">
                <v-col cols=12 xs=12 sm=12  md=6  lg=6  xl=6   >
                  <v-autocomplete
                    v-model="productInfo.currency_id"
                    v-if="hasPurchaseAll"
                    :hint="`${currencies.id} - ${currencies.tipo_cambio} ${currencies.abreviada}`"
                    :items="currencies"
                    item-text="tipo_cambio"
                    item-value="id"
                    label="Selecciona un tipo de cambio*"
                    outlined
                  ></v-autocomplete>  
                </v-col>
                <v-col cols=12 xs=12 sm=12  md=6  lg=6  xl=6   >           
                  <v-text-field
                      v-model="productInfo.num_pedimento"
                      v-if="hasPurchaseAll"
                      label="Número de pedimento"
                  />
                </v-col>
              </v-row>
              <v-row  v-if="hasProduct">
                <v-col cols=12 xs=6 sm=6  md=6  lg=6  xl=6 >
                  <h5>Woocomerce</h5>
                </v-col>
                <v-col cols=12 xs=6 sm=6  md=6  lg=6  xl=6 >
                  <v-switch
                    v-if="hasProduct"
                    v-model="productWooInfo.woo"
                    label="Woo"
                  ></v-switch> 
                </v-col>
              </v-row>
              <v-row v-if="productWooInfo.woo==1">
                <v-col cols=12 xs=12 sm=12  md=4  lg=3  xl=3 >
                  <v-text-field
                      v-model="productInfo.pricelistwoo.clase"
                      v-if="hasProduct"
                      label="Clase de Envio"
                  />
                </v-col>
                <v-col cols=12 xs=12 sm=12  md=4  lg=3  xl=3 >
                  <v-text-field
                      v-model="productInfo.pricelistwoo.peso"
                      v-if="hasProduct"
                      label="Peso"
                  />
                </v-col>            
                <v-col cols=12 xs=12 sm=12  md=4  lg=3  xl=3 >
                  <v-text-field
                      v-model="productInfo.woo_id"
                      v-if="hasProduct"
                      label="Woo ID"
                  />
                </v-col>
                <v-col cols=12 xs=12 sm=12  md=12  lg=12  xl=12 >
                  <v-btn
                          color="purple"
                          class="mr-4"
                          @click="updateWooForm(productInfo.pricelistwoo)" 
                  >
                        <v-icon>save</v-icon> {{buttonText}}
                  </v-btn>    
                </v-col>
              </v-row>            
              <v-row v-if="hasPurchaseAll">
                <v-col cols=12 xs=12 sm=12  md=4  lg=3  xl=3 >
                  <v-text-field
                      v-model="productInfo.comision"
                      v-if="hasPurchaseAll"
                      label="Comisión %"
                  />
                </v-col>
                <v-col cols=12 xs=12 sm=12  md=4  lg=4  xl=4 >
                  <v-text-field
                      v-model="productInfo.shipping_cost"
                      v-if="hasPurchaseAll"
                      label="Costo de Envío"
                  />
                </v-col>
                <v-col cols=12 xs=12 sm=12  md=4  lg=4  xl=4 >
                  <v-text-field
                      v-model="productInfo.other_cost"
                      v-if="hasPurchaseAll"
                      label="Otro Costo"
                  />
                </v-col>
              </v-row>           
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      v-model="productInfo.notes"
                      v-if="hasQuote || hasPurchase || hasProduct || hasPurchaseAll || hasSalesOrder"
                      filled
                      auto-grow
                      label="Escribe comentarios"
                      rows="4"
                      row-height="30"
                      shaped
                  ></v-textarea>  
                </v-col>
              </v-row>

    <v-row>
      <v-col cols="12" >
        <v-btn
                v-if="hasReset"
                color="error"
                class="mr-4"
                @click="reset"
              >
                <v-icon>cleaning_services</v-icon> Reset Form
        </v-btn> 
        <v-btn
                color="primary"
                class="mr-4"
                @click="submitForm(productInfo)" 
                :disabled="!valid"                
        >
              <v-icon>save</v-icon> {{buttonText}}
        </v-btn>               
        <!-- <v-btn
                            v-if="hasLoadProduct"
                            class="mr-4"
                            color="blue darken-1"                            
                            @click="getDataProduct(hasValorID)"
                          >
                             <v-icon>refresh</v-icon>
                             Cargar Datos
                          </v-btn> -->
                          <!-- {{ productInfo.id }} - ID - {{ hasValorID }} -->
                         

      </v-col>
    </v-row>
  </v-form>
</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';
// import{ DataManager,Query, WebApiAdaptor } from "@syncfusion/ej2-data";
import baseApi from '@/services/baseApi';
import router from '@/router';


export default {
  name: 'ProductForm',  
  components: {
  },  
  computed:{
    // 'products',
    ...mapState(['priceslist','suppliers','measurements','familycategories','subfamilies','currencies','brands']) 
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getPrice2(){     
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        // console.log("Pricelist "+sword);
        await this.$store.dispatch('loadPricelists',"All"); //component PricelistItem
        let key=1;
        switch (key) {
          case 1:
            console.log(key);
            break;
        
          default:
            break;
        }
        console.log('Product Form:'+this.$route.name);
        // if (this.$route.name=='PriceList' || this.$route.name=='PriceListViewList') {
          // console.log(this.$route.name);          
          await this.$store.dispatch('loadBrands',this.attribute); //se carga para component PricelistItem
          await this.$store.dispatch('loadMeasurements',this.attribute); //se carga para component PricelistItem
          await this.$store.dispatch('loadFamilyCategories',this.attribute); //se carga para component PricelistItem
          await this.$store.dispatch('loadSubFamilies',this.attribute); //se carga para component PricelistItem
        // }else if(this.$route.name=='DetailsPurchase'){
          // console.log(this.$route.name);          
        // }else{
          // await this.$store.dispatch('loadSuppliers',this.attribute); //se carga para component 
          // await this.$store.dispatch('loadCurrencies',this.attribute);
        // }

      }catch(error){       
        if (error.message=='Request failed with status code 500') {
          this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
        }else if(error.message=='Request failed with status code 401'){
          this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
          router.push('/login');
        }else{
          this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
        } 
      }  finally{
        this.hiddenLoading()
      } 
      
    },
    reset () {
        this.$refs.form.reset()
    },
    getSyscom(pricelist_id) {
          try{        
            this.showLoading({titulo:"Accediendo información en Syscom",color:"warning"})
            baseApi().get(`/syscom/product/${pricelist_id}`).then((response)=>{
              if(!response.data.error){
                this.productInfo=response.data;                             
                //this.$swal("Genial!",response.data.message, "success");
                // this.dialog10=true;
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                  
              //console.log("VENTA: "+response.data.message);
            });
          }catch(error){
            console.log(error);
          }finally{
            this.hiddenLoading()
          } 

      
    },
    getDataProduct(pricelist_id) {
          try{        
            this.showLoading({titulo:"Accediendo información del Cliente",color:"warning"})
            baseApi().get(`precios/${pricelist_id}`).then((response)=>{
              if(!response.data.error){
                this.productInfo=response.data;                             
                //this.$swal("Genial!",response.data.message, "success");
                // this.dialog10=true;
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                  
              //console.log("VENTA: "+response.data.message);
            });
          }catch(error){
            console.log(error);
          }finally{
            this.hiddenLoading()
          } 
      },
  },
  props: {
    user: Object,
    product: Object,
    submitForm:String,
    buttonText:String,
    hasValorID:Number,
    hasLoadProduct:String,hasProductCreate:String,hasProduct:String,
    hasSalesOrder:String,hasQuote:String,hasPurchase:String,hasPurchaseAll:String,hasPricelist:String,
    hasEverybody:String,hasDescription:String,hasReset:String
  },
  data(){
     return{
        valid: false,
        ...validations,
        attribute: {sword: 'All',limit: 27},
        productWooInfo:{
          id:this.product ? this.product.id : "",
          pricelist_id:this.product ? this.product.pricelist_id : "",
          clase:this.product ? this.product.clase : "",
          woo:this.product ? this.product.woo : "",
        },
        productInfo:{   
          id:this.product ? this.product.id : "",
          clave_sat:this.product ? this.product.clave_sat : "",
          sku:this.product ? this.product.sku : "",
          modelo:this.product ? this.product.modelo : "",
          syscom_id:this.product ? this.product.syscom_id : "0",
          ctonline_id:this.product ? this.product.ctonline_id : "0",
          cva_id:this.product ? this.product.cva_id : "0",
          stock_syscom:this.product ? this.product.stock_syscom : "0",
          quote_id:this.product ? this.product.quote_id : "",
          salesorder_id:this.product ? this.product.salesorder_id : "",
          brand_id:this.product ? this.product.brand_id : "",
          measurement_id:this.product ? this.product.measurement_id : "",
          famcategory_id:this.product ? this.product.famcategory_id : "",
          subfamily_id:this.product ? this.product.subfamily_id : "",
          supplier_id:this.product ? this.product.supplier_id : "",
          pricelist_id:this.product ? this.product.pricelist_id : "",
          category:this.product ? this.product.category : "otro",
          customergroup_id:this.product ? this.product.customergroup_id : "1",
          prodcategory_id:this.product ? this.product.prodcategory_id : '1',
          currency_id:this.product ? this.product.currency_id : '14',
          line_id:this.product ? this.product.line_id :  '1',
          metrica:this.product ? this.product.metrica : "",          
          filename:this.product ? this.product.filename : 'https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png',
          canasta_basica:'0',
          type_article:this.product ? this.product.type_article : 'Productos',
          posicion:this.product ? this.product.type_article : '1',
          description:this.product ? this.product.description : "",         
          price:this.product ? this.product.price : "",
          cantidad:this.product ? this.product.cantidad : "",
          min_stock:this.product ? this.product.min_stock : "0",
          max_stock:this.product ? this.product.max_stock : "0",
          comision:this.product ? this.product.comision : "2.99",          
          shipping_cost:this.product ? this.product.shipping_cost : "0", 
          other_cost:this.product ? this.product.other_cost : "0", 
          num_pedimento:this.product ? this.product.num_pedimento : "0",
          status:this.product ? this.product.status : "Público", 
          notes:this.product ? this.product.notes : "", 
        },
        itemsStatus:[        
          'Público',
          'Privado',
          'Descontinuado',
        ],
        itemsTypeArticle:[
          // 'KIT',
          'Productos',
          'Servicios',
        ],
        itemsCustomerGroup:[
          'Regular',
          'Menudeo',
          'Mayorista',
        ],                
        itemsBrand:[
          'Generico',
          'Dahua',
          'iMou',
        ],        
        // dataItem2:new DataManager({
        //     url: "https://www.cloudbits.org.mx/fw/lumen/check/productos/getall",
        //     adaptor: new WebApiAdaptor,
        //     crossDomain:false,
        //     //dataType: 'json',
        //     //method: 'GET',
        //     headers: [
        //       {'Access-Control-Allow-Origin': '*'},
        //       {'Access-Control-Allow-Headers': '*'},
        //       {"Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr'},
        //     ]
        //   }).executeQuery(new Query()).then((e) => {
        //     console.log(e.data);
        //   }),                  
        //   dataFields2:{value:'id',text:'sku'},
        //   //dataFields2:{value:'EmployeeID',text:'FirstName'},
        // };  
     }
  },
  destroyed() {
    console.log("Componente eliminado");
  },
  mounted(){
    console.log("Montado Product Form");
    if (this.hasValorID>0) {
      this.getDataProduct(this.hasValorID)
    }
  },
  created(){
    this.getPrice2()    
  }  
}
</script>