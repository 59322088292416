<template>
  <div class="detailpurchase"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col cols=6 sm=6 md=2 lg=2>          
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps()">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

    <div>
      <table>
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Folio: </b> PC{{ folio }}</td>  </tr>            
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Proveedor:</b> {{ razon_social }}</td>  </tr>            
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Fecha:</b> {{ date_purchase }}</td>  </tr>            
      </table>
    </div>
    <br><br>

<!-- tools -->
<v-card
    flat
    class="py-12"
  >
    <v-card-text>
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >
          <template>  
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">              
                  <v-btn
                    @click="downloadPDF"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                </template>
                <span>Descargar Compra en PDF</span>
              </v-tooltip>
          </template>                                     
          
          <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="orderByAsc"                  
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>format_list_numbered</v-icon>
                </v-btn>
              </template>
              <span>Ordenar la lista de productos</span>
            </v-tooltip>
          </template>    
          
          <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon> 
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Producto / Servicio</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <ProductPurchaseCreate  />
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Producto</span>
            </v-tooltip>
          </template>  
          <!-- Dialog para agregar --> 
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
        </v-btn-toggle>
      </v-row>
    </v-card-text>
  </v-card>
  
  <!-- Lado Izquierdo -->
  <v-row v-if="rows.length > 0">
    <v-col cols="12" xs="12" sm="12" md="8" lg="10" xl="10">      
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
        </thead>     
        <tr v-for="(row) of rows" :key="row.id">       
          <td>          
          <v-img
          :src="row.productfilename"
          :lazy-src="row.productfilename"
          :alt="`${row.productname} avatar`"
          aspect-ratio="1"
          class="grey lighten-2"
          max-height="95"
          max-width="95"
          ></v-img>            
          <br><br>
          <template>  
                      <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="red"
                                x-small
                                fab
                                @click="deleteRow(row.id)"
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Borrar Producto</span>
                          </v-tooltip>
                      </template>                                             
                      
                      <template>  
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="secondary"
                                x-small
                                fab
                                @click="editRow = row.id"
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar Producto</span>
                          </v-tooltip>
                      </template> 
                      
                      <template> 
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">   
                                            <v-btn
                                                color="blue"
                                                x-small
                                                fab
                                                v-on:click="getProduct(row.pricelist_id)"                                  
                                                v-on="on"
                                            >
                                                <v-icon>visibility</v-icon>                  
                                            </v-btn>            
                                          </template>
                                          <span>Detalle Producto</span>
                                        </v-tooltip>
                        </template> 

                        
        </td> 
        <td>
            <a :href="row.url" target="_blank">{{row.productname}}</a>
            <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="getDataProduct(row)"
                          >
                             <v-icon>edit</v-icon>
                          </v-btn>
                          <br>
            <BarcodeGenerator
                :value="row.pricelist_id"
                :format="'CODE39'"
                :lineColor="'#000'"
                :width="2"
                :height="50"
                :elementTag="'img'"
                :displayValue=false
            />              
            <br> 
            <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small> | <small><b>SKU:</b> {{row.productsku}}</small> <br>
            <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productskufab}}</small> <br>                                  
            <small><b>Clave SAT:</b> {{row.clave_sat}}</small> | <small><b>Compra ID:</b> {{row.purchase_id}}</small><br>
            Regular: <span style="font-size:.1.2rem; color:red;">{{row.totalprice_siniva | currency }} <small>  s/iva</small></span><br>
            Mayorista: <span style="font-size:.1.2rem; color:red;">{{row.price_mayorista_siniva | currency }} <small>  s/iva</small></span><br>
            Minorista: <span style="font-size:.1.2rem; color:red;">{{row.price_minorista_siniva | currency }} <small>  s/iva</small></span><br>
            Público: <span style="font-size:.1.2rem; color:red;">{{row.price_publico_siniva | currency }} <small>  s/iva</small></span><br>
            Fecha Creación: {{row.created_at}} <br> 
            Fecha Actualización: {{row.updated_at}} <br> <br>
            <div v-if="editRow === row.id">
            <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Cotización ID" v-model="row.quote_id" /> -->
              <v-autocomplete
                      v-model="row.pricelist_id"
                      :items="priceslist"
                      item-text="description"
                      item-value="id"
                      label="Selecciona un producto*"
                      outlined
              ></v-autocomplete>
              <v-text-field
                          v-model="row.posicion"
                          v-on:keyup.13="updateRow(row)"
                          label="Posición"
              />  
              <v-text-field
                          v-model="row.price"
                          v-on:keyup.13="updateRow(row)"
                          label="Precio"
              />  
              <v-text-field
                          v-model="row.cantidad"
                          v-on:keyup.13="updateRow(row)"
                          label="Cantidad"
              /> 
              <v-radio-group
                  v-model="row.status"
                  label="Status"
                  @change="dateToday(row)"
                  row
                >
                  <v-radio                
                    label="Inventario"
                    value="Inventario"
                  ></v-radio>
                  <v-radio
                    label="En Camino"
                    value="En camino"
                  ></v-radio>                    
              </v-radio-group>  
              <v-date-picker 
                v-model="row.date_arrive" 
                locale="es-mx"
                no-title             
              >
              <!-- full-width -->
              </v-date-picker>         
              <v-text-field                      
                          v-model="row.date_arrive"
                          readonly
                          v-on:keyup.13="updateRow(row)"
                          label="Fecha llegada"
              /> 
              <v-text-field
                          v-model="row.notes"
                          v-on:keyup.13="updateRow(row)"
                          label="Notas"
              /> 

              <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
              <v-btn v-on:click="editRow=false " class="deep-dark">cerrar</v-btn>
            </div>            
            <div v-else>  </div>  
        </td>
        <td>
          <b>Precio de Lista </b>  <br> {{row.price | currency }} <br>  
          <b>Descuento </b> <br> {{(row.price-(row.price*(row.discount/100))) | currency }}  <small style="text-align:center; color:red;">({{row.discount}}%)</small>
        </td>        
        <td>
          {{row.cantidad}}
        </td>
        <td>
          {{ calculateLineTotal(row) | currency }}        
        </td>
        <td>           
          <div style="text-align:center;">                                    
                  <v-chip
                    v-if="row.status == 'Inventario'"
                    class="ma-2"
                    color="green"
                    label
                    text-color="white"
                  >
                    {{row.status}}
                  </v-chip>
                  <v-chip
                    v-else-if="row.status == 'En camino'"
                    class="ma-2"
                    color="default"
                    label
                    text-color="black"
                  >
                    {{row.status}}
                  </v-chip>
                  <v-chip
                    v-else
                    class="ma-2"
                    color="red"
                    label
                    text-color="white"
                  >
                    Revisar
                  </v-chip>          
          </div>
          <div style="text-align:center;">                                    
            <small>{{row.date_arrive}}</small>
          </div>         
        </td>                        
        <td>
          <div v-if="row.notes != null && row.notes != ''">   
            <v-btn
                  color="red lighten-2"
                  dark
                  @click="getNote(row.notes)" 
                >
                  ver nota
                </v-btn>
          </div>
        </td>
        <td></td>        
        <td></td>      
      </tr>
    </table>     
    
    <v-row>
      <v-col cols=12 sm=12 md=12 lg=12>   
        <table>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal:</b> {{ purchase_subtotal | currency }} <small>USD</small></td> 
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Costo de Envío:</b> {{ shippingCost }} <small>USD</small>  </td> 
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Comision:</b> {{ comision }}%</td> 
          </tr>        
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal:</b> {{ purchaseSubtotalUSD | currency }} <small>USD</small></td> 
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Tipo de Cambio:</b> {{ tipoCambio | currency }}</td> 
          </tr>        
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal:</b> {{ purchaseSubtotalMXN | currency }} <small>MXN</small></td> 
          </tr>        
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Aduana:</b> {{ aduanaCost | currency }}</td> 
          </tr>        
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Otro Costo:</b> {{ otherCost | currency }}</td> 
          </tr>        
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Desc:</b> {{ desc | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Importe:</b> {{ purchase_subtotal2 | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>IVA:</b> {{ iva | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Total:</b> {{ totalcost | currency }}</td> 
          </tr>
        </table>
      </v-col>
    </v-row>      
  </v-col>
  <!-- Lado Derecho -->  
  <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2">      
    <h5 >Ordenar Lista {{ draggingInfo }}</h5>
    <div class="form-check">
          <input
            id="disabled"
            type="checkbox"
            v-model="enabled"
            class="form-check-input"
          />
          <label class="form-check-label" for="disabled">Habilitar</label>
    </div>
    <br><br>
      <v-card
        class="mx-auto"
        max-width="100%"
      >
        <v-list>
          <v-list-item-group       
            mandatory     
            color="indigo"
          >
              <draggable
                :list="rows"
                :disabled="!enabled"
                class="list-group"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging=false"
                @end="order2()"
              >
                <v-list-item
                  v-for="(row,index) in rows"
                  :key="row.id"                  
                  v-slot="{ active, toggle }"
                >
                  <v-list-item-avatar>
                    <v-img
                        :src="row.productfilename"
                        :lazy-src="row.productfilename"
                        :alt="`${row.productname} avatar`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        max-height="95"
                        max-width="95"
                    ></v-img>                    
                  </v-list-item-avatar>
                  <v-list-item-content
                    :color="active ? 'primary' : ''"
                    @click="toggle"
                  >                    
                    <v-list-item-title>
                      <a :href="row.url" target="_blank">{{row.productname}}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productskufab}}</small> <br>                      
                      <small><b>SKU:</b> {{row.productsku}}</small><br>
                      <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small><br>
                      Index: {{index+1}} -  Posicion {{row.posicion}}
                    </v-list-item-subtitle>                    
                  </v-list-item-content>                   
                </v-list-item>
              </draggable>
          </v-list-item-group>
        </v-list>     
      </v-card>  
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols=12 sm=12 md=12 lg=12 >
    <v-alert
            dense
            outlined
            type="error"
          >
            No hay registros encontrados con esta busqueda
      </v-alert>
    </v-col>
  </v-row>


<!-- VISTA PARA EDITAR PRODUCTO -->
<template>                 
                      <v-dialog
                        v-model="dialog10"
                        persistent
                        max-width="800px"                 
                      >
                     
                          <v-card>
                            <v-card-title>
                              <span class="headline">Editar Producto </span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialog10 = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <ProductEdit :product="product" />                                
                              </v-container>
                          </v-card-text>
                          <v-card-actions>
                              <v-btn 
                                color="red" 
                                @click="dialog10 = false"
                              >
                                cerrar
                              </v-btn>
                              <v-spacer></v-spacer>   
                              <small style="color:red;">*Campos Obligatorios</small>
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
              </template>    
<!-- FIN VISTA PARA EDITAR PRODUCTO -->

<!-- VISTA PRODUCTO DIALOG-->
<template>                 
                      <v-dialog
                        v-model="dialogViewProduct"
                        persistent
                        max-width="800px"                 
                      >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle Producto</span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialogViewProduct = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-container v-if='this.dialogViewProduct==true'>
                                <ProductView :productid="productid"  />
                            </v-container>
                            <!-- :pricelist="priceslist -->
                            <!-- buttonText="Guardar"   -->
                          <v-card-actions>
                              <v-btn 
                              
                              color="red" 
                              @click="dialogViewProduct = false"
                              >
                              cerrar
                            </v-btn>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
      </template>                 
      <!-- FIN VISTA PRODUCTO  DIALOG-->

      <!-- DIALOG NOTAS -->
   <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->
      
        


  </div>
</template>

<script>
// import axios from "axios";
import draggable from "vuedraggable";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import jsPDF from 'jspdf' 
import domtoimage from "dom-to-image";
//import DetailQuoteAdd from "@/components/DetailQuoteAdd.vue";
import baseApi from '@/services/baseApi';
import ProductPurchaseCreate from "@/components/ProductPurchaseCreate";
import ProductEdit from "@/components/ProductEdit";
import ProductView from "@/components/ProductView";
import BarcodeGenerator from "@/components/BarcodeGenerator";




export default {
  name: 'detailpurchase',
  computed:{
    ...mapState(['currentUser','priceslist']),
    draggingInfo() {
        return this.dragging ? "...arrastrando" : "";
    } 
  },  
  components: {
      //FieldSearch,
      draggable,
      ProductEdit,
      ProductView,
      ProductPurchaseCreate,
      BarcodeGenerator
  },      
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    checkMove: function(e) {      
      window.console.log("Nombre: " + e.draggedContext.element.productname);
      window.console.log("Future index: " + e.draggedContext.futureIndex);
      window.console.log("Origen index: " + e.draggedContext.index);
      window.console.log("destino index: " + e.relatedContext.index);  
      this.index=e.draggedContext.index;
      this.order=e.relatedContext.index;      
      window.console.log("Index: " + this.index);
      window.console.log("Orden: " + this.order);      
    },
    orderByAsc(){
        let i=0;      
        let size=this.rows.length;      
        for (i; i < size; i++) { 
              this.rows[i].posicion = i+1;                       
              this.updateRow(this.rows[i]); //Funciona pero pasa todos los valores
              window.console.log("Posicion: " + this.rows[i].posicion);
        }                 
    },
    order2(){
        this.dragging=false;
        let i=0;
        let id=this.index;      
        let order=this.order;
        let size=this.rows.length;

        // alert("Por fin lo solto");
        // console.log("ID: "+id)
        // console.log("Size: "+this.list.length)            

        if (order>id){        
            for (i = id; i < size; i++) { 
              this.rows[i].posicion = i+1;                       
            }          
        }else{
          for (i = order; i < size; i+=1) { 
            this.rows[i].posicion = i+1;                       
          }
        }
        
    },    
    dateToday(row){
      if(row.status == "Inventario"){
        //alert("Estas como inventario")
        row.date_arrive=this.sfecha;
      }else{
        row.date_arrive="";
      }
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },  
    calculateLineTotal(row) {     
            // console.log(row.price);
            var stotal = parseFloat(row.price) * parseFloat(row.cantidad);
            var total = parseFloat(stotal-(stotal*(row.discount/100)));                        
            
            if (!isNaN(total)) {
                //row.line_total = total.toFixed(2);
                //total.toFixed(2);                
                //return total.toFixed(2);
                return total;
                
            }
            //return  total;
            //this.calculateTotal(total);
    },        
    async getBreadcrumps(){      
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];

      try{            
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        let branch_id=this.currentUser.branch_id;
        let datos= await baseApi().get(`/detallecompra/`+this.$route.query.purchaseid+`?branch_id=`+branch_id);
        // let datos= await baseApi().get(`/detalleventa/`+this.$route.query.salesorderid+`?branch_id=`+branch_id);
        // console.log(datos.data.length);
        if(datos.data.length >0){
          this.rows=datos.data;            
          for (var i = 0; i < this.rows.length; i+=1) {   
            // console.log(this.rows[i]);
            this.folio=this.rows[0].purchase_id;            
            this.razon_social=this.rows[0].razon_social; 
            this.date_purchase=this.rows[0].date_buy; 
            //this.subtotal=(this.subtotal + (this.rows[i].price*this.rows[i].cantidad));  //Se multiplica con uno para que lo convierta en numero            

            let total_line=this.calculateLineTotal(this.rows[i]);
            // console.log(total_line);
            //this.quote_subtotal=(this.quote_subtotal + (total_line-(total_line*(this.rows[i].discount/100))));  //Se multiplica con uno para que lo convierta en numero                        
            this.purchase_subtotal=this.purchase_subtotal + total_line;

            this.purchase_id=this.rows[0].purchase_id;
            this.otherCost=this.rows[0].other_cost;
            this.shippingCost=this.rows[0].shipping_cost;
            this.tipoCambio=this.rows[0].tipo_cambio;
            this.comision=this.rows[0].comision;
            this.aduanaCost=this.rows[0].aduana_cost;

            let purchaseSubTotal=this.purchase_subtotal + parseFloat(this.rows[0].shipping_cost);
            this.purchaseSubtotalUSD=purchaseSubTotal + (purchaseSubTotal * (parseFloat(this.rows[0].comision)/100));
            this.purchaseSubtotalMXN=this.purchaseSubtotalUSD * (parseFloat(this.rows[0].tipo_cambio));

            let purchaseTotal=this.purchaseSubtotalMXN+parseFloat(this.rows[0].aduana_cost)+parseFloat(this.rows[0].other_cost);            
            
            if (this.rows[0].purchasediscount!=0) this.desc=(this.purchase_subtotal*(this.rows[0].purchasediscount/100)); 
            else this.desc=0;

            this.purchase_subtotal2=purchaseTotal-this.desc;
            
            if (this.rows[0].purchaseiva!=0) this.iva=(purchaseTotal*0.16); 
            else this.iva=0;            

            this.totalcost=this.purchase_subtotal2 + this.iva;            
          }                     
          //this.desserts=datos.data          
        }else{
          this.rows=[];
        }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 

      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})                        
        await this.$store.dispatch('loadPricelists',"All");
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      }
    },
    downloadPDF(){
        //const doc = new jsPDF('p', 'mm', [297, 210]); 
        const doc = new jsPDF('l', 'mm', [297, 210]);           
        var folio="PU"+this.rows[0].purchase_id;
        doc.page = 1; // use this as a counter.
        var totalPages = 0; // define total amount of pages
        var leyenda="";
        // HEADER        
        doc.setFontSize(16);//optional
        // doc.setTextColor(40);//optional
        doc.setFont(undefined, "bold");
        doc.text("COMPRA NORMAL ",30, 18);// set your margins
        doc.setFontSize(10);
        doc.setFont(undefined, "normal");
        doc.text(this.rows[0].title,30, 23);// set your margins
        // FIN FOOTER

        //var imgData = 'data:image/jpeg;base64,verylongbase64;'
        //var imgData = 'https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png';
        //var imgData ='https://pbs.twimg.com/profile_images/996822127700406272/hNY5DZw-_normal.jpg';
        var imgData ='https://lh3.googleusercontent.com/ogw/ADGmqu_nde3VWYvJC7lGnEWQ1s7UkYMpyVLGkcERiiiY=s64-c-mo';
        doc.addImage(imgData, 'JPEG', 9, 10, 15, 15);


        //console.log(imgData); 
        
        //doc.text(15, 15, reportTitle);
        //doc.addImage(outputURL, 'JPEG', 15, 40, 180, 100);

        
        //var miArray = [ 2, 4, 6, 8, 10 ];
        //console.log(this.rows.length);
        //console.log(this.rows);
        //console.log(this.rows[1].id);        

        doc.setFontSize(9);
        doc.text("Folio: "+folio,227.7,15);
        doc.text("Fecha: "+this.rows[0].created_at,226,21);
        doc.text("Cliente: "+this.rows[0].razon_social,225,27);

                
        var yh=45;
        doc.setFontSize(8);
        //doc.setFontType("bold"); No funciona
        // this.setFont("helvetica", "bold");
        doc.setFont(undefined, "bold");
        doc.text("CANT.",7,yh);
        doc.text("DESCRIPCIÓN",17,yh);        
        doc.text("PRECIO DE\rLISTA",205,yh,'center');
        doc.text("DESC.",225,yh,'center')        
        doc.text("PRECIO\rUNITARIO",240,yh,'center')        
        doc.text("IMPORTE",255,yh);
        doc.setFont(undefined, "normal");                  

        
        var y=55;   
        var total=0;    
        var stotal=0;   
        var desc=0;   
        var stotal2=0;   
        var iva=0;            
        var gtotal=0;
        var priceuni=0;
        var str = "";
        doc.setFontSize(7);

        for (var i = 0; i < this.rows.length; i+=1) {     
          priceuni=this.rows[i].price-(this.rows[i].price*(this.rows[i].discount/100));
          if (y>=191)    {
            yh=34;
            y=40;
            totalPages+=1;            
            // FOOTER     
            str = "Página " + doc.page  + " de " +  totalPages;               
            doc.setFontSize(7);// optional
            doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
            doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function                  

            doc.addPage(); 
            doc.setFontSize(8);
            //doc.setFontType("bold"); No funciona
            // this.setFont("helvetica", "bold");
            doc.setFont(undefined, "bold");
            doc.text("CANT.",7,yh);
            doc.text("DESCRIPCIÓN",17,yh);        
            doc.text("PRECIO DE\rLISTA",205,yh,'center');
            doc.text("DESC.",225,yh,'center')        
            doc.text("PRECIO\rUNITARIO",240,yh,'center')        
            doc.text("IMPORTE",255,yh);
            doc.setFont(undefined, "normal");                 
            doc.setFontSize(7);                            
          }
          total=0;    
          //total=this.rows[i].cantidad*this.rows[i].price;
          total=this.calculateLineTotal(this.rows[i]);          
          stotal+=total;
          //console.log("En el índice '" + i + "' hay este valor: " + this.rows[i].id);
          //console.log("total: " + total);
          
          doc.text(this.rows[i].cantidad.toString(),10,y,'center'); 
          if ( this.rows[i].url ==  null)
            doc.text(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(),17,y);
          else
            doc.textWithLink(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(), 17, y, {url: this.rows[i].url.toString()});                   
          doc.text("$"+this.rows[i].price.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),205,y,'center');          
          doc.text("-"+this.rows[i].discount.toString()+"%",225,y,'center');
          doc.text("$"+priceuni.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),240,y,'center');
          doc.text("$"+total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),255,y);
          //doc.text(String(total | currency).toFixed(2),245,y);
          y = y + 12;          
        }   
        
        if (this.rows[0].purchasediscount!=0) desc=stotal*(this.rows[0].purchasediscount/100);
        else desc=0;

        stotal2=stotal-desc;
        

        if (this.rows[0].iva!=0) iva=stotal2*0.16;
        else iva=0;
        
        gtotal=stotal2+iva;        

        y = y+6;  
        doc.setFontSize(8);      
        doc.text("Subtotal:  $"+stotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),235,y); 
        y = y+6;   
        if (desc>=1){
          doc.text("Descuento:  $"+desc.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),231.7,y); 
          y = y+6;        
          doc.text("Subtotal c/Desc:  $"+stotal2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),225.5,y); 
          y = y+6;        
        }                                
        doc.text("IVA:  $"+iva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),240.5,y); 
        y = y+6;        
        doc.setFontSize(10);
        doc.text("TOTAL: $"+gtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),233.7,y);

        //Nota
        if (this.rows[0].notes!=""){
          y = y+30;                
          doc.setFontSize(8);        
          doc.setTextColor(0,0,0);          
          doc.text("NOTA:",10,y);   
          y = y+5;                   
          doc.setTextColor(40);          
          doc.text(this.rows[0].notes,10,y);
        }        



        // FOOTER   
        totalPages+=1;             
        str = "Página " + doc.page  + " de " +  totalPages;        
        doc.setFontSize(7);// optional          
        // doc.setFont("helvetica");
        // doc.setFontType("bold");
        doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
        doc.setTextColor(0,85,136);
        doc.text(leyenda, 43, doc.internal.pageSize.height - 10);//key is the interal pageSize function
        doc.setTextColor(0,0,0);
        doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
        
        //doc.text(this.name,15,15);
        doc.save(folio+".pdf")
    },
    downloadWithCSS() {
      /** WITH CSS */
      domtoimage
      .toPng(this.$refs.content)
      .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        const doc = new jsPDF({
          orientation: "portrait",
          // unit: "pt",
          format: [900, 1400]
        });
        doc.addImage(img, "JPEG", 20, 20);
        const date = new Date();
        const filename =
          "timechart_" +
          date.getFullYear() +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          ("0" + date.getDate()).slice(-2) +
          ("0" + date.getHours()).slice(-2) +
          ("0" + date.getMinutes()).slice(-2) +
          ("0" + date.getSeconds()).slice(-2) +
          ".pdf";
        doc.save(filename);
      })
      .catch(function(error) {
        console.error("oops, something went wrong!", error);
      });
    },  
    deleteRow(id) {      
      // alert(id);
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"});
          
          if(confirm("Desea borrar definitivamente el registro: "+ id+"?")){
            // alert("Estoy en OK")
            baseApi().get(`/detallecompra/delete/${id}`).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("COTIZACION: "+response.data.message);
            });
          }else{
            // alert("NO acepto :(")
          }          
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },                 
    getDataProduct(row) {       
          this.product=row
          this.dialog10=true
    }, 
    async getProduct(ProductID){
      console.log('Producto ID:'+ProductID);
      this.productid=ProductID;
      // alert(this.productid);
      this.dialogViewProduct=true;      
    }, 
    async getProduct2(ProductID){
      this.dialog12=true;
      console.log('Producto ID:'+ProductID);
      baseApi().get(`/precios/${ProductID}`).then((response)=>{
              if(!response.data.error){                            
                // this.$swal("Genial!",response.data.message, "success");
                this.productrow=response.data
                // this.rows.productfilename=response.data.filename
                //alert(this.rows.productfilename)

              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
      });

      
    },  
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                
          baseApi().post(`/detallecompra/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
          });                                                                                                     
          // console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    }    
  },
  data(){
    return{
      enabled: false,
      dialog: false,        
      dialog1: false,        
      dialog10: false,   
      dialogViewProduct:false,
      dialogNotes:false,
      notes:'',   
      toggle_exclusive:'',
      dragging: false,
      sword:"",
      j:1,
      folio:"",
      razon_social:"",
      date_purchase:"",
      subtotal:0,
      desc:0,      
      sfecha:new Date().toISOString().substr(0,10),
      productrow:{},
      product:'',
      rows: [],
      editRow:null,
      purchase_id:'',
      price:"",
      purchase_subtotal: 0,
      purchaseSubtotalUSD:0,
      purchaseSubtotalMXN:0,
      purchase_subtotal2: 0,
      purchase_subtotal_desc:0,
      totalcost:0,
      shippingCost:0,      
      comision:0,
      tipoCambio:0,
      otherCost:0,      
      aduanaCost:0,      
      purchase_tax: 16,      
      purchase_iva: 0,   
      iva:0,  
      theader: [
        { text: 'Acción', value: 'canasta_basica' },       
        // {
        //   text: 'ID',
        //   align: 'start',
        //   sortable: false,
        //   value: 'id',
        // },
        
        { text: 'Producto', value: 'subtotal_quote' },
        { text: 'Precio de Lista', value: 'precio_other' },        
        { text: 'Cantidad', value: 'cantidad' },                
        { text: 'Total', value: 'status' },               
        { text: 'Status', value: 'status' },
        { text: 'Notas', value: 'status' },
      ],
      acciones: [
        { title: 'Download PDF',icon:"save",metodo:"downloadPDF" }
      ],      
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Detalle de Compra "+this.sword,
        title:"Lista de detalle de Compra",
        subtitle:"primary",
        description:"En esta lista encontrará todos los detalles de la compra dada de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps()     
  }
}
</script>
<style>
  .v-date-picker-table {    
    height: auto;
  }
</style>