<template>
  <div class="measurements"> 
    <template>
      <v-container class="grey lighten-5">
        <v-row >
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <h5>Buscar:</h5>
            <input type="text" placeholder="Escribe una palabra" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
          </v-col>              
        </v-row>            
      </v-container>
    </template>



    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
        <!-- Nuevo Registro SubFamily -->                       
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                      @click="dialog=true"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar Nuevo Registro</span>
                </v-tooltip>
            </template>         
        <!-- FIN Nuevo Registro SubFamily -->                       
                
        <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="getMeasurement('')" 
                      color="primary darken-5"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Página</span>
                </v-tooltip>
            </template>
            
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>

<span class="right"><b>{{ measurements.length }}</b> registro(s) encontrados <br></span>
<br><br>
<!-- table -->
<template>
  <!-- height="700px" -->
  <v-simple-table
    fixed-header
    dense
  >
    <template v-slot:default>
      <thead>
        <tr><th class="text-left" v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>
      </thead>
      <tbody>
        <!-- v-for="row in subfamilies" -->
        <tr
          v-for="(row,index) of measurements"
          :key="row.id"
        >
            <td>
            <!-- 
            <button v-on:click="deleteFriend(row.id, i)">x</button>
            -->
            <v-btn v-on:click="editRow = row.id" icon>
              <v-icon>edit</v-icon>
            </v-btn>
                          
          </td>   
          <td>
            <div v-if="editRow === row.id">      
              <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.customer_id" /> -->
              <input v-on:keyup.13="updateRow(row)" placeholder="Siglas" v-model="row.siglas" />
              <input v-on:keyup.13="updateRow(row)" placeholder="Nombre" v-model="row.name" />
              <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
              <v-btn v-on:click="editRow=false;" class="default">Cerrar</v-btn>
            </div>
            <div v-else>  
              {{ index+1 }}
            </div>        
          </td>  
          <td>{{ row.id }}</td>
          <td>{{row.siglas}}</td>
          <td>{{row.name}}</td>    
          <td>
            <div v-if="row.ver == '1'">        
              <v-chip
              class="ma-2"
              color="green"
              label
              text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                Activo
              </v-chip>
            </div>                 
            <div v-else>
              <v-chip
              class="ma-2"
              color="default"
              label
              text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                Desactivado
              </v-chip>
            </div>                                                                       
          </td>            
          <td>{{formatDate(row.updated_at)}}</td>     
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<!-- FIN table -->

<!-- Dialog Unidad de Medida Create -->
<v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
     
      <v-card>
        <v-card-title>
          <span class="headline">Nueva Unidad de Medida</span>
          <v-btn
            class="headline"
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <EventForm :submitForm="AddMeasurement" buttonText="Guardar" hasMeasurement=true />          
          </v-container>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <small style="color:red;">*Campos Obligatorios</small>          
        </v-card-actions>
      </v-card>
</v-dialog>
<!-- Dialog Unidad de Medida Create -->
 <!-- DIALOG NOTAS -->
<div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
</div>
<!-- FIN DIALOG NOTAS -->



  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations, mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import baseApi from '@/services/baseApi';
import EventForm from "@/components/EventForm";
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"

export default {
  name: 'measurements',
  computed:{
        ...mapState(['measurements'])
  },
  components: {
      //FieldSearch,
      EventForm,
  },
  methods:{
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
      formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
      },  
      getNote(note){
        // console.log('Producto ID:'+ProductID);
        this.notes=note;
        // alert(this.productid);
        this.dialogNotes =true;  
      },  
      async getMeasurement(sword){       
        this.attribute.sword=sword;
        try{
          this.showLoading({titulo:"Accediendo información",color:"primary"})
          this.$store.dispatch('loadMeasurements',this.attribute);          
          this.attribute.sword="All";
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
      },  
      async getBreadcrumps(sword){       
        this.getMeasurement(sword);
      },               
      async AddMeasurement(MeasurementInfo){
        // alert("Agregar Evento");
        // alert (MeasurementInfo.name);
        try{ 
          this.showLoading({titulo:"Almacenando información",color:"warning"})
          let name=MeasurementInfo.name;
          let siglas=MeasurementInfo.siglas;
          // const { salesorder_id,customer_id,fecha_inicio,cantidad } = this;        
          //let datos=await baseApi().post();          
          await baseApi().post(`/umedidas`,JSON.stringify({ name,siglas})).then((response)=>{
              if(!response.data.error){                            
                // this.$swal("Genial!","", "success");
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
          });
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        } 
      },       
      updateRow(row) {      
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})        
            baseApi().post(`/umedidas/update/${row.id}`,JSON.stringify(row)).then((response)=>{
                if(!response.data.error){                            
                  // this.$swal("Genial!","", "success");
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                // console.log("VENTA: "+response.data.message);
            });                                                                                                    
            // console.log(datos);
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialog:false,
      // dialogNote:false,
      sword:"",      
      editRow:null,
      itemsStatus:[        
          'Proceso',
          'Contactado',
          'No Responde',
          'Finalizado'
      ],      
      theader: [
          { text: 'Acción', value: 'canasta_basica' },
          { text: '#', value: 'id' },
          { text: 'ID', value: 'id' },
          { text: 'Siglas', value: 'notes' },
          { text: 'Descripción', value: 'name' },
          { text: 'Status', value: 'created_at' },        
          { text: 'Actualización', value: 'updated_at' },        
      ],
    }
  }, 
  created(){
    this.changeBreadcrumbs({
          page:"Unidad de Medida",
          title:"Lista de Unidad de Medida",
          subtitle:"primary",
          description:"En esta lista encontrará todas las unidades de Medida dadas de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  }
}
</script>