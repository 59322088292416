<!-- 
  2022-09-1
  Problemas con la etiqueta y/o lib ejs-combobox  
-->
<template>
  <v-col cols="12"    xs="12"     sm="12"     md="3"     lg="3"     xl="2">
    <v-card>
        <div v-if='pricelist.filename == ""'>
          <v-img
            lazy-src="https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png"
            height="150"
            src="https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png"
          ></v-img>                                    
        </div> 
        <div v-else>
          <v-img
            v-bind:lazy-src="pricelist.filename"
            max-height="150"
            
            v-bind:src="pricelist.filename"
          ></v-img>
        </div>  
        <br> 
        
        <center>          
        <BarcodeGenerator
              :value="pricelist.id"
              :format="'CODE39'"
              :lineColor="'#000'"
              :width="1"
              :height="40"
              :elementTag="'img'"
              :displayValue=false
          /> <br>
        </center>
        <v-card-title class="my-0 py-0">
         <span v-if='pricelist.stock <= 0' style="color:red; line-height: 1.4em; font-size: .9rem;">{{pricelist.description}}</span>
         <span v-else-if='pricelist.stock <= pricelist.min_stock' style="color:orangered;">{{pricelist.description}}</span>
         <span v-else color="blue">{{pricelist.description}}</span>                 
        </v-card-title>

        
        <v-card-text>      
          <div class="my-4 subtitle-1">
            <span style="font-size:2rem; color:green;">{{pricelist.price_publico | currency }}</span> <br>
            <span style="font-size:.1.2rem; color:red;">{{pricelist.price_publico/1.16 | currency }} <small>  s/iva</small></span><br>              
            <v-chip
              class="ma-2"
              color="green"
              label
              text-color="white"
              >              
                <v-icon left>production_quantity_limits</v-icon>
                {{pricelist.stock }}              
              </v-chip>       
              <!-- v-if='pricelist.camino > 0' -->
              <v-chip                
                class="ma-2"
                color="blue"
                label
                text-color="white"
                >
                  <v-icon left>local_shipping</v-icon>
                  {{pricelist.camino }}
              </v-chip>            
              <v-chip                
                class="ma-2"
                color="red"
                label
                text-color="white"
                >
                  <v-icon left>inventory</v-icon>
                  {{pricelist.min_stock}}
              </v-chip>            
          </div>
        </v-card-text>

        <div style="text-align:left;">                                  
                  <!-- <v-chip
                    v-if="pricelist.status == 'Público'"
                    class="ma-2"
                    color="green"
                    label
                    text-color="white"
                  >
                    Activo
                  </v-chip>
                  <v-chip
                    v-else-if="pricelist.status == 'Privado'"
                    class="ma-2"
                    color="red"
                    label
                    text-color="white"
                  >
                      Activo
                  </v-chip>
                  <v-chip
                    v-else
                    class="ma-2"
                    color="default"
                    label
                    text-color="white"
                  >
                    Desactivado
                  </v-chip>           -->
                  
                  <!-- <v-card-subtitle> -->
                    <h6 class="py-1 pl-5">
                      <b> {{pricelist.modelo}} </b> <br>  
                    </h6>   
                    <div class='py-1 pl-5'>
                     <b>Marca: </b> {{pricelist.brandname}}
                    </div>

                    <div class='py-1 pl-5'>
                      <b>SKU:</b> {{pricelist.sku}}
                    </div>
                    <div class='py-1 pl-5'>
                      <b>Clave SAT:</b> {{pricelist.clave_sat}}                       
                    </div> 
                    <div class='py-1 pl-5'>
                      <b>Clave SYSCOM:</b> {{pricelist.syscom_id}}                       
                    </div> 
                    <div class='py-1 pl-5'>
                      <b>Clave CTOnline:</b> {{pricelist.ctonline_id}}                       
                    </div>                      
                    <center class="py-1 pl-5">
                      Stock Syscom:  {{pricelist.stock_syscom}}  <br>          
                      Stock CTOnline:  {{pricelist.stock_ctonline}} <br>          
                      Stock CVA: {{pricelist.stock_cva}} <br>          
                    </center>
                      
                  <!-- </v-card-subtitle> -->
        </div> 

        <v-divider class="mx-4"></v-divider>        

        <v-card-text>
          <div v-if="editRow === pricelist.id"> 
            <h6>Actualizar información</h6>
            <v-text-field
              v-model="pricelist.branch_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="Sucursal ID"       
              placeholder="Sucursal ID"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="pricelist.warehouse_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="almacen ID"       
              placeholder="almacen ID"
            ></v-text-field>
            <v-text-field
              v-model="pricelist.sku"
              v-on:keyup.13="updateRow(pricelist)"
              label="sku"       
              placeholder="sku"              
            ></v-text-field>
            <v-text-field
              v-model="pricelist.filename"
              v-on:keyup.13="updateRow(pricelist)"
              label="Imagen URL"       
              placeholder="imagen URL"
            ></v-text-field>
            <v-text-field
              v-model="pricelist.url"
              v-on:keyup.13="updateRow(pricelist)"
              label="URL eCommerce"       
              placeholder="URL eCommerce"
            ></v-text-field>            
            <v-text-field
              v-model="pricelist.description"
              v-on:keyup.13="updateRow(pricelist)"
              label="Descripción"       
              placeholder="Descripción"
            ></v-text-field>  
            <v-text-field
              v-model="pricelist.clave_sat"
              v-on:keyup.13="updateRow(pricelist)"
              label="Clave SAT"       
              placeholder="Clave SAT"
            ></v-text-field>  
            <v-text-field
              v-model="pricelist.modelo"
              v-on:keyup.13="updateRow(pricelist)"
              label="Modelo"       
              placeholder="Modelo"
            ></v-text-field> 
            <v-text-field
              v-model="pricelist.price"
              v-on:keyup.13="updateRow(pricelist)"
              label="precio iOnOff"       
              placeholder="precio iOnOff"
            ></v-text-field>                   
            <v-combobox
              v-model="pricelist.type_article" 
              :items="itemsTypeArticle"
              label="Seleccione un tipo de artículo"
            ></v-combobox>
            <br> 
            <!-- <v-autocomplete
              v-model="pricelist.supplier_id"
              :hint="`${pricelist.supplier_id}`"
              :items="suppliers"
              item-text="razon_social"
              item-value="id"
              label="Selecciona un Proveedor"  
              outlined
            ></v-autocomplete> -->
            <v-autocomplete
              v-model="pricelist.brand_id"
              :hint="`${pricelist.brand_id}`"
              :items="brands"
              item-text="name"
              item-value="id"              
              label="Selecciona una Marca"  
              outlined
            ></v-autocomplete>
            
            <v-autocomplete
              v-model="pricelist.measurement_id"
              :hint="`${pricelist.measurement_id}`"
              :items="measurements"
              item-text="name"
              item-value="id"              
              label="Selecciona una Unidad Medida"  
              outlined
            ></v-autocomplete>
        
            <!-- <v-text-field
              v-model="pricelist.supplier_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="proveedor ID"       
              placeholder="proveedor ID"
            ></v-text-field> -->
            
            <!-- <v-autocomplete
              v-model="pricelist.product_id"
              :hint="`${pricelist.product_id} - ${description}`"
              :items="products"
              item-text="description"
              item-value="id"
              label="Selecciona un Producto"  
              outlined
            ></v-autocomplete> -->                        
            
            <v-autocomplete
              v-model="pricelist.famcategory_id"
              :hint="`${pricelist.famcategory_id}`"
              :items="familycategories"
              item-text="name"
              item-value="id"              
              label="Selecciona una Familia"  
              outlined
            ></v-autocomplete>

            <v-autocomplete
                    v-model="pricelist.subfamily_id"
                    :hint="`${pricelist.subfamily_id}`"
                    :items="subfamilies"
                    item-text="name"
                    item-value="id"              
                    label="Selecciona una Sub-Familia"  
                    outlined
                  ></v-autocomplete>  

             
    

            <!-- <ejs-combobox 
                    v-model="pricelist.type_article" 
                    label="Tipo de Artículo*"
                    :dataSource='dataIArticle' 
                    :fields='dataFArticle' 
                    placeholder='Seleccione una tipo de artículo' 
                    popupWidth="250px"                    
                  >
            </ejs-combobox>   -->
            <v-switch
              v-model="pricelist.meli"
              label="Mercado Libre"
            ></v-switch>             
            <v-text-field
              v-model="pricelist.meli_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="Mercado libre ID"       
              placeholder="Mercado libre ID"
            ></v-text-field>
            <v-text-field
              v-model="pricelist.meli_porcentaje"
              v-on:keyup.13="updateRow(pricelist)"
              label="Mercado libre Comision"       
              placeholder="Mercado libre Comision"
            ></v-text-field>
            <v-switch
              v-model="pricelist.syscom"
              label="Syscom"
            ></v-switch>             
            <v-text-field
              v-model="pricelist.syscom_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="Syscom ID"       
              placeholder="Syscom ID"
            ></v-text-field>
            <v-switch
              v-model="pricelist.ctonline"
              label="CTOnline"
            ></v-switch>             
            <v-text-field
              v-model="pricelist.ctonline_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="CTOnline ID"       
              placeholder="CTOnline ID"
            ></v-text-field>
            <v-switch
              v-model="pricelist.cva"
              label="CVA"
            ></v-switch>             
            <v-text-field
              v-model="pricelist.cva_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="CVA ID"       
              placeholder="CVA ID"
            ></v-text-field>
            <v-switch
              v-model="pricelist.woo"
              label="Woo"
            ></v-switch>  
            <v-text-field
              v-model="pricelist.woo_id"
              v-on:keyup.13="updateRow(pricelist)"
              label="Woocommerce ID"       
              placeholder="Woocommerce ID"
            ></v-text-field>
            
                     
            <!-- <v-radio-group
              v-model="pricelist.woo"
              label="Woo"
              row
            >
              <v-radio                
                label="Sincronizado"
                :value="1"
              ></v-radio>
              <v-radio
                label="No Sincronizado"
                :value="0"
              ></v-radio>                    
            </v-radio-group>   -->
            <!-- <v-text-field
              v-model="pricelist.woo"
              v-on:keyup.13="updateRow(pricelist)"
              label="Woo Ver"       
              placeholder="Woo Ver"
            ></v-text-field> -->            
            <v-text-field
              v-model="pricelist.min_stock"
              v-on:keyup.13="updateRow(pricelist)"
              label="min stock"
              placeholder="mínimo de stock necesario"
            ></v-text-field>   
            <v-text-field
              v-model="pricelist.max_stock"
              v-on:keyup.13="updateRow(pricelist)"
              label="max stock"
              placeholder="maximos de stock necesario"
            ></v-text-field>                        
            <v-combobox
              v-model="pricelist.status" 
              :items="itemsStatus"
              label="Seleccione un Status"
            ></v-combobox> 
            <!-- <ejs-combobox v-on:keyup.13="updateRow(pricelist)" 
                v-model="pricelist.status" 
                :dataSource='dataItem' 
                :fields='dataFields' 
                placeholder='Seleccione una fuente' 
                popupWidth="250px">
            </ejs-combobox> -->
            <v-btn v-on:click="updateRow(pricelist)" class="green">Actualizar</v-btn>
            <v-btn v-on:click="editRow=false" class="deep-dark">cerrar</v-btn>
          </div>        
           
        <!-- QTY no funciona por que no existe en el arreglo-->
        <!-- Botones Carrito de compras -->
        <v-card 
          flat 
          class="py-5"
          max-width="210"
          >
          <v-card-text class="my-0 py-0">
            <v-row class="text-right" >        
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
              >
              <!-- <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">              
                      <v-btn
                        @click="disminuir(pricelist)" 
                        color="red"               
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                      <v-icon>remove</v-icon>
                      </v-btn>
                    </template>
                    <span>Disminuir cantidad</span>
                  </v-tooltip>
              </template> -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">  
                      <!-- <v-responsive
                        class="mx-auto"
                        max-width="450"
                      >                     -->
                        <!-- rounded -->
                        <!-- single-line -->
                        <!-- append-icon="mdi-magnify" -->
                        <!-- class="escsizeinput" -->
                        <!-- label="Filled" -->
                        <!-- variant="outlined" -->
                        <v-text-field
                          class="my-0 py-0"
                          v-model="pricelist.qty"                
                          placeholder="Cantidad"
                          type="number"
                          min="0" 
                          step="1"
                          :value="1"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        ></v-text-field>
                      <!-- </v-responsive> -->
                    </template>
                    <span>Ingresa cantidad</span>
                  </v-tooltip>
              </template> 
              <!-- <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">              
                      <v-btn
                        @click="aumentar(pricelist)"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >                
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Aumentar cantidad</span>
                  </v-tooltip>
              </template>   -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">              
                      <v-btn
                        @click="addCar(pricelist)" 
                        color="green"               
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                      <v-icon color="green" >add_shopping_cart</v-icon> Agregar
                      </v-btn>
                    </template>
                    <span>Agregar al carrito de compras</span>
                  </v-tooltip>
              </template>
                
              </v-btn-toggle>
            </v-row>
          </v-card-text>
        </v-card>  
       <!-- FIN Botones Carrito de compras -->
      </v-card-text>

      <v-divider class="mx-4 my-4"></v-divider>


  
                              
        <!--  
            <v-chip-group
              v-model="selection"
              active-class="deep-purple accent-4 white--text"
              column
            >
              <v-chip>5:30PM</v-chip>

              <v-chip>7:30PM</v-chip>

              <v-chip>8:00PM</v-chip>

              <v-chip>9:00PM</v-chip>
            </v-chip-group>
        -->

    <v-card-actions>             
      <!-- Botones Acciones -->
      <v-card 
          flat 
          class="py-0"
          max-width="210"
        >
          <v-card-text class="my-0 py-0">
            <v-row class="text-right" justify="center" >        
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
              >
              <!-- Link a Web -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">              
                      <v-btn
                        v-if='pricelist.url != null'
                        color="deep-white default"
                        v-bind:href="pricelist.url"
                        target="_blank"
                        v-on="on"
                      >                
                        <v-icon>public</v-icon>
                      </v-btn>
                    </template>
                    <span>Ir al eCommerce</span>
                  </v-tooltip>
              </template>
              <!-- FIN Link a Web -->

              <!-- Edit -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ attrs }">              
                      <v-btn  
                        color="deep-purple warning"
                        v-on:click="editRow = pricelist.id" 
                        v-bind="attrs"
                        >                
                        <!-- v-on:click="getDataProduct(pricelist.id)" -->
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Articulo</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Edit -->
              
              <!-- View -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        color="blue"
                        v-on:click="getProduct(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                         <v-icon>visibility</v-icon>                         
                        </v-btn>
                      </template>
                      <span>Detalle del Articulo</span>
                  </v-tooltip>
              </template>           
              <!-- FIN View -->

              <!-- History -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        color="deep-black"
                        v-bind:href="url(pricelist.id)"   
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                         <v-icon>view_list</v-icon>                         
                        </v-btn>
                      </template>
                      <span>Historial del Articulo</span>
                  </v-tooltip>
              </template> 
              <!-- FIN History -->
                  

            </v-btn-toggle>
            </v-row>
          </v-card-text>
      </v-card>  
      <!-- FIN Botones Acciones -->
    </v-card-actions>

    <v-card-actions> 
      <!-- Botones Acciones -->
      <v-card 
          flat 
          class="py-0"
          max-width="210"
        >
          <v-card-text class="my-0 py-0">
            <v-row class="text-right" justify="center" >        
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
              >

               <!-- Subir a Meli -->
               <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.meli == 1'
                        @click="upProductMeli(pricelist.id)"
                        color="yellow default"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>publish</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar Producto a Mercado libre</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Subir a Meli -->

              <!-- Sincronizar con WC -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.woo == 1'
                        @click="pricechangeWoo(pricelist.id)"
                        color="deep-orange default"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>price_change</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con Woocommerce</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con WC -->


              <!-- Subir a WC -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.woo == 0'
                        @click="upProductWoo(pricelist.id)"
                        color="deep-purple default"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>publish</v-icon>
                      </v-btn>
                    </template>
                    <span>Subir Producto a Woocommerce</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Subir a WC -->
              
              <!-- Sincronizar con Syscom -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.syscom == 1'
                        color="deep-purple blue"
                        @click="upProductSyscom(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>publish</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con Syscom</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con Syscom -->

              <!-- Sincronizar con CT -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.ctonline == 1'
                        color="deep-red red"
                        @click="upProductCTOnline(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>download</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con CTOnline</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con CT -->

              <!-- Sincronizar con CVA -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.cva == 1'
                        color="deep-green green"
                        @click="upProductCVA(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>download</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con CVA</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con CVA -->

            </v-btn-toggle>
            </v-row>
          </v-card-text>
      </v-card>  
      <!-- FIN Botones Acciones -->                             
    </v-card-actions> 
  </v-card>

      <!-- VISTA PRODUCTO DIALOG-->
      <template>                 
                      <v-dialog
                        v-model="dialogViewProduct"
                        persistent
                        max-width="800px"                 
                      >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle Producto</span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialogViewProduct = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-container v-if='this.dialogViewProduct==true'>
                                <ProductView :productid="productid"  />
                            </v-container>
                            <!-- :pricelist="priceslist -->
                            <!-- buttonText="Guardar"   -->
                          <v-card-actions>
                              <v-btn 
                              
                              color="red" 
                              @click="dialogViewProduct = false"
                              >
                              cerrar
                            </v-btn>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
      </template>                 
      <!-- FIN VISTA PRODUCTO  DIALOG--> 
        
  </v-col>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
import baseApi from '@/services/baseApi';
// import router from '@/router';
import BarcodeGenerator from "@/components/BarcodeGenerator";
import ProductView from "@/components/ProductView";




export default {
  props:{
    pricelist:{
      type:Object,
      required:true
    },
    // idPropietario: {
    //   type: [Boolean, Number],
    //   default: false
    // },
  },
  // emits: ['name-event'],
  computed:{
    //'products''suppliers',
      ...mapState(['brands','measurements','familycategories','subfamilies','currentUser'])
  },
  components: {    
    BarcodeGenerator,
    ProductView
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    url: function (href){
        return 'detalleventasproducto?pricelistid='+ href
    },    
    aumentar(row){ 
      // this.pricelist.qty=0;
      // if(row.qty==null) row.qty=0
      row.qty ++
    },
    disminuir(row){      
      row.qty --
    },
    async getProduct(ProductID){
      console.log('Producto ID:'+ProductID);
      this.productid=ProductID;
      // alert(this.productid);
      this.dialogViewProduct=true;      
    }, 
    updateRow(row) {      
      try{                
        this.showLoading({titulo:"Actualizando información",color:"primary"})  
        // alert(row.id)
        baseApi().post(`/precios/update/${row.id}`,JSON.stringify(row)).then((response)=>{        
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("VENTA: "+response.data.message);
        });              
      }catch(error){
        console.log('Update Pricelist '+error);
        // if (error.message=='Request failed with status code 500') {
        //   this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
        // }else if(error.message=='Request failed with status code 401'){
        //   this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
        //   router.push('/login');
        // }else{
        //   this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
        // } 
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    },      
    async addCar(row){
        try{        
          this.showLoading({titulo:"Agregando al Carrito",color:"default"})      
          console.log(row.id);       
          //let branch_id=this.currentUser.branch_id;          
          let pricelist_id=row.id;
          let price=row.price_publico/1.16; //Se divide 1.16 para que sea el precio sin iva
          let cantidad=row.qty;
          
          await baseApi().post(`/carrito`,JSON.stringify({ pricelist_id,price,cantidad })).then((response)=>{
            if(!response.data.error){        
              this.$emit('name-event', true);
              // this.$swal("Genial!",response.data.message, "success");
              // this.$swal({
              //   position: "top-end",
              //   icon: "success",
              //   title: "Se agreo al carrito el producto "+pricelist_id,
              //   showConfirmButton: false,
              //   timer: 1500,
              //   timerProgressBar: true,                              
              // });
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
                // console.log("VENTA: "+response.data.message);
          });                      
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
    },
    async upProductMeli(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Subir informacion a Mercado Libre",color:"blue"})
          
          await baseApi().get('/mlibre/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductSyscom(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Obtener informacion de Syscom",color:"blue"})
          
          await baseApi().get('/syscom/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductCTOnline(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Obtener informacion de CTOnline",color:"red"})
          
          await baseApi().get('/ctconnect/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductCVA(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Obtener informacion de CVA",color:"green"})
          
          await baseApi().get('/cva/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductWoo(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Creando el producto en Woocommerce",color:"default"})
          
          await baseApi().get('/woo/products/create/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async pricechangeWoo(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Actualizando el precio en Woocommerce",color:"default"})
          // let branchID=this.currentUser.branch_id;          
          // await baseApi().get('/woo/products/updatepriceone/'+id+'?branch_id='+branchID).then((response)=>{  
          await baseApi().get('/woo/products/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message+"2", "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
  },
  data(){
    return{
      // attribute: {sword: 'All',limit: 500},
      editRow: null,
      toggle_exclusive:'',
      dialogViewProduct: false,
      productid:0,
      itemsStatus:[        
          'Público',
          'Privado',
          'Descontinuado',
      ],
      dataFields:{value:'id',text:'game'},
      itemsTypeArticle:[
         //'KIT',
         'Productos',
         'Servicios',
      ],
      dataIArticle:[
         {id: 'KIT', game: 'KIT'},
         {id: 'Productos', game: 'Productos'},
         {id: 'Servicios', game: 'Servicios'}         
        ],
      dataFArticle:{value:'id',text:'game'}
    }
  }
}
</script>

<style>
  .v-card__title{
    height: 140px;
    align-content: baseline;
    font-size:1rem;
    line-height:1.5rem;
  }  

  .escsizeinput{
    padding-top: 2px;
    padding-bottom:0px;
  }
  
</style>